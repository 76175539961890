import React, { useContext } from "react";

//Context
import { AppContext } from "../../contexts/AppContext";
import "./header.css";
import { Col, Row } from "reactstrap";
import Menus from "../../components/Header/Menus";
import MegaMenu from "../../components/Header/MegaMenu";
import ShortTextOutlinedIcon from "@mui/icons-material/ShortTextOutlined";
import logo from "../../Assests/mmlogo.png";
function Header() {
  const { handleDrawer } = useContext(AppContext);

  return (
    <>
      <div className="header">
        <div className="container">
          <Col className="d-flex align-items-center header_logo">
            <a href="/">
              <img height={50}  width={"auto"} src={logo} alt="logo" />
            </a>
          </Col>
          <Col className="d-flex align-items-center justify-content-end" md="8">
            {/* desktop menu */}

            <Row className="desktop_only">
              {" "}
              <MegaMenu
                name="Tour Packages"
                submenu={["Sightseeing", "Slum Tours", "Unique Experience"]}
              />
              <Menus
                name="About Us"
                submenu={["Team", "Mission Values", "Contact"]}
              />
              <Menus name="Social Impact" />
              <Menus button="true" name="Plan Your Trip" />
            </Row>

            {/* Mobile menu */}
            <div className="mob_only">
              <ShortTextOutlinedIcon
                onClick={handleDrawer}
                fontSize="medium"
                htmlColor="black"
              />
            </div>
          </Col>
        </div>
      </div>
    </>
  );
}

export default Header;
